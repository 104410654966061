import React, { useState } from 'react';
import Input from '../components/system/input';
import Modal from '../components/system/modal';
import Nav from '../components/system/nav';
import Table from '../components/system/table';
import moment from 'moment';
import { Link } from 'react-router-dom';

function Blogs() {
  let [createModal, showCreateModal] = useState(false);
  let [deleteModal, showDeleteModal] = useState(false);
  let [deleteData, setDeleteData] = useState({});
  let [data, setData] = useState();

  let [inputs, setInputs] = useState({ data: [], status: 1, type: 3 });

  let [triggerFetch, setTriggerFetch] = useState(0);

  let createPage = () => {
    window.app
      .service('pages')
      .create({
        ...inputs,
        uri: '/' + inputs.name.replace(/[^a-zA-Z0-9]/g, '_'),
      })
      .then((response) => {
        window.Alert.notify({
          title: 'Page Created',
        });

        showCreateModal(false);
        fetchBlogs();
        return response;
      })
      .catch((err) => {
        console.error(err);
      });

    setTriggerFetch(triggerFetch + 1);
  };

  let deletePage = () => {
    window.app
      .service('pages')
      .patch(deleteData.id, { status: 0 })
      .then((response) => {
        window.Alert.notify({
          title: 'Page Deleted',
        });

        showCreateModal(false);
        fetchBlogs();
        return response;
      })
      .catch((err) => {
        console.error(err);
      });

    setTriggerFetch(triggerFetch + 1);
  };

  let fetchBlogs = async (query) => {
    return await window.app
      .service('pages')
      .find({
        query: {
          ...query,
          type: 3,
          status: {
            $gt: 0,
          },
        },
      })
      .then((response) => {
        setData(response);
      })
      .catch((err) => {
        // this.setState({ table_loading: false });
        console.error(err);
      });

    // console.log(filter);
  };

  return (
    <div className=''>
      <Nav
        title='Blogs'
        links={[
          {
            text: 'Create an Article',
            onClick: () => {
              showCreateModal(true);
            },
          },
        ]}></Nav>

      <div className='card page-card p-3 mx-3 shadow-sm'>
        <Table
          sort={{ createdAt: -1 }}
          search={['name']}
          data={data}
          triggerFetch={triggerFetch}
          fetch={fetchBlogs}
          entry={(data) => ({
            // id: data.id,
            name: data.name,
            // url: data.uri,
            last_updated: moment(data.updatedAt).calendar(),
            '': (
              <div className='flex-row'>
                <Link
                  to='/'
                  className='btn btn-outline-primary btn-sm btn-round me-3'>
                  View
                </Link>
                <Link
                  to={`/blog${data.uri}/${data.id}`}
                  className='btn btn-outline-primary btn-sm btn-round me-3'>
                  Edit
                </Link>
                <button
                  onClick={() => {
                    setDeleteData(data);
                    showDeleteModal(true);
                  }}
                  className='btn btn-outline-danger btn-round btn-sm'>
                  Delete
                </button>
              </div>
            ),
          })}></Table>
      </div>

      <Modal
        isActive={createModal}
        close={showCreateModal}
        title='Create Article'>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            createPage();
          }}>
          <Input
            label='page name'
            required
            onChange={(name) => {
              setInputs({ ...inputs, name });
            }}
          />

          <button className='btn btn-primary btn-round mt-4'>Submit</button>
        </form>
      </Modal>

      <Modal
        isActive={deleteModal}
        close={showDeleteModal}
        title={'Delete ' + deleteData.name + ' ?'}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            deletePage();
          }}>
          <Input
            label='Enter Case Study name'
            required
            onChange={(name) => {
              setInputs({ ...inputs, name });
            }}
          />

          <button className='btn btn-primary btn-round mt-4'>Submit</button>
        </form>
      </Modal>
    </div>
  );
}

export default Blogs;
