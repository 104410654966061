import moment from 'moment';
import React, { useState } from 'react';
import Form from '../components/system/form';
import Input from '../components/system/input';
import Modal from '../components/system/modal';
import Nav from '../components/system/nav';
import Table from '../components/system/table';

function Users() {
  let [data, setData] = useState();
  let [createModal, showCreateModal] = useState(false);
  let [editModal, showEditModal] = useState(false);
  let [inputs, setInputs] = useState({ data: [], status: 1 });

  let [user, setUser] = useState();

  let createUser = () => {
    console.log('creating user');
    window.app
      .service('users')
      .create(inputs)
      .then((response) => {
        window.Alert.notify({
          title: 'User added successfully',
        });

        showCreateModal(false);

        console.log(response);
        fetchData();
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let updateUser = () => {
    window.app
      .service('users')
      .patch(user.id, user)
      .then((response) => {
        window.Alert.notify({
          title: 'User updated successfully',
        });

        showEditModal(false);

        fetchData();
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let fetchData = async (query) => {
    return await window.app
      .service('users')
      .find({
        query: { ...query, status: 1 },
      })
      .then((response) => {
        setData(response);
      })
      .catch((err) => {
        // this.setState({ table_loading: false });
        console.error(err);
      });
  };

  return (
    <div className=''>
      <Nav
        title={'Users'}
        links={[
          {
            text: 'Add a User',
            onClick: () => showCreateModal(true),
          },
        ]}></Nav>

      <div className='card page-card p-3  mx-3 shadow-sm'>
        <Table
          data={data}
          fetch={fetchData}
          entry={(data) => ({
            name: `${data.firstname} ${data.lastname}`,
            email: data.email,
            created: moment(data.createdAt).calendar(),
            '': (
              <button
                className='btn-sm btn btn-outline-primary'
                onClick={() => {
                  setUser(data);
                  showEditModal(true);
                }}>
                Edit
              </button>
            ),
          })}></Table>
      </div>

      <Modal isActive={createModal} close={showCreateModal} title={'Add User '}>
        <Form
          onSubmit={createUser}
          onChange={(data) => {
            setInputs({ ...data, type: 3, status: 1 });
          }}
          submit={'Submit'}
          inputs={[
            {
              label: 'first name',
              key: 'firstname',
              required: true,
              autocomplete: 'off',
            },
            {
              label: 'last name',
              key: 'lastname',
              required: true,
              autocomplete: 'off',
            },

            {
              label: 'email',
              key: 'email',
              type: 'email',
              required: true,
              autocomplete: 'off',
            },
            {
              label: 'password',
              key: 'password',
              type: 'password',
              required: true,
              autocomplete: 'off',
            },
          ]}
        />
      </Modal>

      <Modal isActive={editModal} close={showEditModal} title={'Edit User '}>
        {user && (
          <Form
            onSubmit={updateUser}
            onChange={(data) => {
              setUser({ ...user, ...data });
            }}
            submit={'Submit'}
            inputs={[
              {
                label: 'first name',
                key: 'firstname',
                required: true,
                autocomplete: 'off',
                initialValue: user.firstname,
              },
              {
                label: 'last name',
                key: 'lastname',
                required: true,
                autocomplete: 'off',
                initialValue: user.lastname,
              },

              {
                label: 'email',
                key: 'email',
                type: 'email',
                required: true,
                autocomplete: 'off',
                initialValue: user.email,
              },
              {
                label: 'password',
                key: 'password',
                type: 'password',
                required: true,
                autocomplete: 'off',
              },
            ]}
          />
        )}
      </Modal>
    </div>
  );
}

export default Users;
