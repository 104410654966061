import React, { useCallback, useEffect, useMemo, useState } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, useSlate, Slate } from 'slate-react';
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
} from 'slate';
import { withHistory } from 'slate-history';
import {
  ListOl,
  ListUl,
  Quote,
  TypeBold,
  TypeItalic,
  TypeUnderline,
  Plus,
  CollectionPlay,
  JustifyLeft,
  ClipboardData,
  FileRichtext,
} from 'react-bootstrap-icons';
import Modal from '../system/modal';
import Card from './Card';
import Media from '../media';
import Column from '../system/column';
import SlateRichText from './slateRichtext';
import { v4 } from 'uuid';
import StatsCard1 from './statsCard1';
import StatsCard2 from './statsCard2';
import TextSection from './textSection';
import TextSectionSlider from './textSectionSlider';

let emptyParagraph = {
  type: 'paragraph',
  children: [
    {
      text: '',
    },
  ],
};

let components = {
  Media: {
    component: Media,
    name: 'Media',
    icon: CollectionPlay,
    data: {
      el: 'Media',
      type: 'card',
      children: [emptyParagraph],
    },
  },
  SlateRichText: {
    component: SlateRichText,
    name: 'Text',
    icon: JustifyLeft,
    data: {
      el: 'SlateRichText',
      type: 'card',
      children: [emptyParagraph],
    },
  },
  StatsCard1: {
    component: StatsCard1,
    name: 'Stats Card',
    icon: ClipboardData,
    data: {
      el: 'StatsCard1',
      type: 'card',
      children: [emptyParagraph],
      size: 'col-md-12',
    },
  },
  StatsCard2: {
    component: StatsCard2,
    name: 'Stats Card 2',
    icon: ClipboardData,
    data: {
      el: 'StatsCard2',
      type: 'card',
      children: [emptyParagraph],
      size: 'col-md-12',
    },
  },
  TextSection: {
    component: TextSection,
    name: 'Text Section',
    size: 'col-md-12',
    icon: FileRichtext,
    data: {
      el: 'TextSection',
      type: 'card',
      children: [emptyParagraph],
      size: 'col-md-12',
    },
  },
  TextSectionSlider: {
    component: TextSectionSlider,
    name: 'Text Slider',
    size: 'col-md-12',
    icon: FileRichtext,
    data: {
      el: 'TextSectionSlider',
      type: 'card',
      children: [emptyParagraph],
      size: 'col-md-12',
    },
  },
};

// import { Button, Icon, Toolbar } from '../components';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const BasicEdior = (props) => {
  const [value, setValue] = useState(props.value || []);
  const [componentModal, setComponentModal] = useState(false);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const renderElement = useCallback(
    (props) => <Element {...props} editor={editor} />,
    []
  );

  useEffect(() => {
    // if (value.length && value[value.length - 1].type !== 'paragraph') {
    //   setValue([...value, emptyParagraph]);
    // }
  }, []);

  return (
    <div className='richtext'>
      <Slate
        editor={editor}
        value={value}
        onChange={(value) => {
          // console.log('current Value', value);

          setValue(value);
          if (props.onChange) props.onChange(value);
        }}>
        <ToolBar>
          <HeaderSelect></HeaderSelect>

          {/* <BlockButton format='heading-one' icon='h1' />
          <BlockButton format='heading-two' icon='h2' /> */}

          <MarkButton format='bold' icon={<TypeBold></TypeBold>} />
          <MarkButton format='italic' icon={<TypeItalic></TypeItalic>} />
          <MarkButton
            format='underline'
            icon={<TypeUnderline></TypeUnderline>}
          />
          <MarkButton format='code' icon='code' />

          <BlockButton format='block-quote' icon={<Quote></Quote>} />
          <BlockButton format='numbered-list' icon={<ListOl></ListOl>} />
          <BlockButton format='bulleted-list' icon={<ListUl></ListUl>} />
        </ToolBar>
        <div className='py-3 ' id='editable-space'>
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder='Enter some rich text…'
            spellCheck
            autoFocus
            onKeyDown={(event) => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
            }}
          />
        </div>

        <button
          className='btn btn-sm btn-outline-primary w-100 sticky-component-btn'
          onClick={(e) => {
            e.preventDefault();
            setComponentModal(true);
          }}>
          {' '}
          <Plus></Plus> Add Component
        </button>
      </Slate>

      <Modal
        title='Add component'
        isActive={componentModal}
        close={setComponentModal}>
        <div className='row component-list'>
          {Object.keys(components).map((index, i) => {
            let item = components[index];
            let Icon = item.icon;

            return (
              <div className='col-md-3 component-item' key={index}>
                <div
                  className='action-icon p-2'
                  onClick={() => {
                    Transforms.insertNodes(
                      editor,
                      { id: v4(), ...item.data },
                      { select: true }
                    );

                    setComponentModal(false);
                  }}>
                  <Icon size={40} />
                  <div className=''>{item.name}</div>
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
    </div>
  );
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type),
    split: true,
  });
  const newProperties = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format) => {
  const { selection } = editor;
  if (!selection) return false;
  // added section
  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

let updateElement = (editor, element, data) => {
  Transforms.setNodes(editor, data, {
    at: [],
    match: (node, path) => {
      return node.id === element.id;
    },
  });
};

const Element = ({ attributes, children, element, editor }) => {
  if (element.el) {
    let Component = components[element.el].component;

    return (
      <Column
        {...element}
        columnWidth={element.size}
        onChange={(data) => {
          updateElement(editor, element, data);
        }}
        remove={(e) => {
          e.preventDefault();
          console.log('remove');
          Transforms.removeNodes(editor, {
            at: [],
            match: (n) => {
              return n.id === element.id;
            },
          });
        }}>
        <Component
          {...attributes}
          {...element}
          onChange={(data) => {
            updateElement(editor, element, data);
            // console.log(data);
            // element = { ...element, data };
          }}>
          {children}{' '}
        </Component>
      </Column>
    );
  }

  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>;
    case 'heading-four':
      return <h4 {...attributes}>{children}</h4>;
    case 'heading-five':
      return <h5 {...attributes}>{children}</h5>;
    case 'heading-six':
      return <h6 {...attributes}>{children}</h6>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <div
      className={
        'toolbar-item border-right action-icon ' +
        (isBlockActive(editor, format) && 'active')
      }
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}>
      {icon}
    </div>
  );
};

const HeaderSelect = () => {
  const editor = useSlate();
  const { selection } = editor;

  let type;

  if (selection) {
    let [match] = Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => {
        return [
          'paragraph',
          'heading-one',
          'heading-two',
          'heading-three',
          'heading-four',
          'heading-five',
          'heading-six',
        ].includes(n.type);
      },
    });

    if (match && match[0]) {
      type = match[0].type;
    }
  }

  return (
    <div>
      <select
        onChange={(e) => {
          e.preventDefault();
          toggleBlock(editor, e.target.value);
        }}
        className={
          ' form-control form-control-sm toolbar-item border-right action-icon '
        }
        value={type}>
        <option value='paragraph' default selected>
          paragraph
        </option>
        <option value='heading-one'>Heading 1</option>
        <option value='heading-two'>Heading 2</option>
        <option value='heading-three'>Heading 3</option>
        <option value='heading-four'>Heading 4</option>
        <option value='heading-five'>Heading 5</option>
        <option value='heading-six'>Heading 6</option>
      </select>
    </div>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <div
      className={
        'toolbar-item border-right action-icon ' +
        (isMarkActive(editor, format) && 'active')
      }
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}>
      {icon}
    </div>
  );
};

const ToolBar = ({ children }) => {
  return (
    <div className='editor-toolbar p-3 py-2 border-bottom shadow-sm bg-white'>
      {children}
    </div>
  );
};

const initialValue = [
  { id: 1, type: 'media', el: 'Media', children: [{ text: '' }], void: true },
  {
    id: 4,
    type: 'text',
    el: 'SlateRichText',
    children: [
      { id: 20, type: 'paragraph', children: [{ text: 'cool content' }] },
    ],
    void: true,
  },
];

export default BasicEdior;
