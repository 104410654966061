import React, { useEffect, useState } from 'react';
import Nav from '../components/system/nav';
import PageEditor from '../components/page/basicEditor';
import Form from '../components/system/form';
import Card from '../components/card';
import { useParams } from 'react-router-dom';
import Swiper from '../components/swiper';
import BasicEdior from '../components/page/basicEditor';
import Media from '../components/media';

let previewWindow = null;

function PageEditCaseTudy() {
  let [data, setData] = useState({ data: {} });
  let [loading, setLoading] = useState(true);
  let [categories, setCategories] = useState([]);

  let categoryName;

  let { id, uri } = useParams();

  let save = () => {
    window.app
      .service('pages')
      .patch(id, data)
      .then((response) => {
        window.Alert.notify({
          title: 'Page Updated',
        });
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let fetchPage = () => {
    window.app
      .service('pages')
      .get(id)
      .then((response) => {
        console.log(response);

        setData(response);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchPage();
    fetchCategories();

    document.addEventListener('keydown', (e) => {
      if (e.ctrlKey && e.code === 'KeyS') {
        e.preventDefault();
        save();
      }
      if (e.ctrlKey && e.code === 'KeyP') {
        e.preventDefault();
        save();
      }
    });
  }, []);

  let updateData = (inputs) => {
    let d = data;
    d.data = { ...data.data, ...inputs };

    setData(d);
  };

  let fetchCategories = () => {
    window.app
      .service('page-category')
      .find({
        query: {
          status: 2,
        },
      })
      .then((response) => {
        console.log('page categories ', response);
        setCategories(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let createCategory = () => {
    console.log(categoryName);
    window.app
      .service('page-category')
      .create({
        name: categoryName,
        status: 2,
      })
      .then((response) => {
        let article = { ...data };
        article.pageCategoryId = response.id;
        categoryName = '';

        let $categories = [...categories];
        $categories.push(response);
        setCategories($categories);

        setData(article);
      });
  };

  return (
    <div className=''>
      <Nav title={`Edit Page`}></Nav>

      {!loading && (
        <div className='row px-4'>
          <div className='col-md-9 '>
            <Card className={'mb-4'}>
              {/* {'ss' + data.data.title} */}
              <Form
                onChange={updateData}
                inputs={[
                  {
                    label: 'title',
                    key: 'title',
                    required: true,
                    initialValue: data.data.title,
                    className: 'clean',
                    style: {
                      fontSize: 30,
                      fontFamily: '"Libre Baskerville", serif',
                      fontWeight: 'bold',
                    },
                  },
                  {
                    label: 'campaign',
                    key: 'campaign',
                    required: true,
                    initialValue: data.data.campaign,
                    style: {
                      fontFamily: '"Montserrat", sans-serif',
                    },
                  },
                  {
                    label: 'client',
                    key: 'client',
                    type: 'textarea',
                    required: true,
                    initialValue: data.data.client,
                    size: 'col-md-3',
                  },
                  {
                    label: 'Platforms',
                    key: 'platforms',
                    type: 'textarea',
                    required: true,
                    initialValue: data.data.platforms,
                    size: 'col-md-3',
                  },
                  {
                    label: 'Our Role',
                    key: 'role',
                    type: 'textarea',
                    required: true,
                    initialValue: data.data.role,
                    size: 'col-md-3',
                  },
                  {
                    label: 'Deliverables',
                    key: 'deliverables',
                    type: 'textarea',
                    required: true,
                    initialValue: data.data.deliverables,
                    size: 'col-md-3',
                  },
                ]}></Form>
              <div className='label'>Banner</div>
              <Media
                onChange={({ uri }) => {
                  updateData({ banner: uri });
                }}
                uri={data.data.banner}></Media>

              <Swiper
                slides={data.data.slides}
                onChange={({ slides }) => {
                  console.log('slides', slides);
                  updateData({ slides });
                }}></Swiper>
              <BasicEdior
                value={
                  data.data.content || [
                    {
                      id: 20,
                      type: 'paragraph',
                      children: [{ text: '' }],
                    },
                  ]
                }
                onChange={(content) => {
                  updateData({ content });
                }}></BasicEdior>
            </Card>

            <Card title='SEO'>
              <Form
                onChange={updateData}
                inputs={[
                  {
                    label: 'SEO title',
                    key: 'seo_title',
                    required: true,
                    initialValue: data.data.seo_title,
                  },
                  {
                    label: 'SEO description',
                    key: 'seo_description',
                    type: 'textarea',
                    required: true,
                    initialValue:
                      data.data.seo_description || data.data.intro_text,
                  },
                ]}></Form>
            </Card>
          </div>

          <div className='col-md-3 position-relative'>
            <Card title='PAGE SETTINGS' className='sticky-card'>
              <Form
                onChange={(inputs) => {
                  setData({ ...data, ...inputs });
                }}
                inputs={[
                  {
                    label: 'Page Name',
                    key: 'name',
                    required: true,
                    initialValue: data.name,
                  },
                  {
                    label: 'Page URL',
                    key: 'uri',
                    disabled: true,
                    required: true,
                    initialValue: data.uri,
                  },
                  {
                    label: 'Page Order',
                    key: 'page_order',
                    required: false,
                    initialValue: data.page_order,
                  },
                ]}></Form>

              <div className='mb-4'>
                <label className='label'>category</label>
                {categories.length > 0 && (
                  <select
                    className='form-control'
                    value={data.pageCategoryId}
                    onChange={(e) => {
                      if (e.target.value == 0) {
                        window.alert2.show({
                          title: 'Add Category',
                          message: (
                            <div>
                              <div class='form-group mb-0'>
                                <input
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    categoryName = e.target.value;
                                  }}
                                  type='text'
                                  required={true}
                                  class='form-control'
                                  placeholder='Enter Category'
                                />
                              </div>
                            </div>
                          ),
                          close: true,
                          onSubmit: () => {
                            createCategory();
                          },
                        });

                        return false;
                      }

                      let $data = { ...data };
                      $data.pageCategoryId = e.target.value;
                      setData($data);
                    }}>
                    <option value='' hidden selected>
                      Choose Category
                    </option>
                    {categories.map((d) => (
                      <option value={d.id}>{d.name}</option>
                    ))}
                    <option value='0'>Other</option>
                  </select>
                )}
              </div>

              <div className='row'>
                <div className='col-md-6'>
                  <button
                    className='btn btn-outline-primary w-100'
                    onClick={save}>
                    Save
                  </button>
                </div>
                <div className='col-md-6'>
                  <button
                    className='btn btn-outline-primary w-100'
                    onClick={() => {
                      if (!previewWindow || previewWindow.closed) {
                        previewWindow = window.open(
                          `${window.stagingServer}/${uri}/${id}`,
                          'child-window'
                        );
                      }

                      previewWindow.focus();
                    }}>
                    Preview
                  </button>
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
}

export default PageEditCaseTudy;
