import React, { useState } from 'react';
import { PlusCircle, Trash } from 'react-bootstrap-icons';
import TextSection from './textSection';

function TextSectionSlider(props) {
  let [slides, setSlides] = useState(props.slides || [{}]);

  return (
    <div className='my-4 border-top border-bottom py-3'>
      <p className='label'> SLIDES </p>

      <div className='row align-items-center mt-3'>
        {slides &&
          slides.map((slide, i) => (
            <div key={i} className='col-md-6 mb-4 edit-parent'>
              <div className='edit-menu shadow-sm px-3'>
                <div
                  className='action-icon'
                  onClick={() => {
                    let $slides = [...slides];
                    $slides.splice(i, 1);
                    setSlides($slides);
                  }}>
                  <Trash></Trash>{' '}
                  <span>
                    {' '}
                    <small> Delete</small>
                  </span>
                </div>
              </div>

              <TextSection
                {...slide}
                onChange={(data) => {
                  let $slides = [...slides];
                  $slides[i] = { ...$slides[i], ...data };
                  setSlides($slides);

                  props.onChange({ slides: $slides });
                }}>
                {props.children}
              </TextSection>
            </div>
          ))}

        <div className='col-md-3' contentEditable={false}>
          <div
            className=' media media-icon action-icon mb-3 py-4'
            onClick={() => {
              let $slides = [...slides];
              $slides.push({});
              setSlides($slides);
            }}>
            <PlusCircle size={40}></PlusCircle>

            <div className='mt-2'>Add Slide</div>
          </div>
          <div className='d-none'>{props.children}</div>
        </div>
      </div>
    </div>
  );
}

export default TextSectionSlider;
