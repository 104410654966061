import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../components/card';
import Nav from '../components/system/nav';
import start from '../img/work.svg';
import logo from '../img/logo_black.svg';

function Home() {
  let navigate = useNavigate();

  return (
    <div className='px-md-4'>
      <div className=' py-4'>
        <div className='intro-parent'>
          <div className='col-md-6'>
            <img src={logo} className='intro-logo' alt='' />
            <div className='mt-2 '>
              |{'  '} {/* <small> */}
              Tandem Network
              {/* </small> */}
            </div>
          </div>
        </div>
      </div>
      <div className='containe'>
        <div className='card py-4 px-5 mt-2 intro-card my-5'>
          <div className='row'>
            <div className='col-md-8'>
              <h4 className='font-weight-bold intro-title'>Welcome Back !</h4>
              <div className='mt-3'>
                To get started, use the menu on your left. All the general
                summaries will be displayed on this page. Any new system updates
                will be displayed here.
              </div>
            </div>
            <div className='col-md-4 flex-row justify-content-center d-md-flex d-none'>
              <img src={start} alt='' className='intro-img' />
            </div>
          </div>
        </div>

        <div className='row '>
          <p className='label'>What would you like to do?</p>

          <div className='row action-list mt-4 p-0 m-0'>
            <div className='col-md-3'>
              <Card
                title={'Manage Blogs'}
                className='action-item text-center'
                onClick={() => {
                  navigate('/blogs');
                }}>
                <img
                  src={window.fileserver + 'blogs.png'}
                  className='action-img mt-3'
                  alt=''
                />
              </Card>
            </div>
            <div className='col-md-3'>
              <Card
                title={'Manage Careers'}
                className='action-item text-center'
                onClick={() => {
                  navigate('/careers');
                }}>
                <img
                  src={window.fileserver + 'career.png'}
                  className='action-img mt-3'
                  alt=''
                />
              </Card>
            </div>
            <div className='col-md-3'>
              <Card
                title={'Manage Case Studies'}
                className='action-item text-center'
                onClick={() => {
                  navigate('/case-studies');
                }}>
                <img
                  src={window.fileserver + 'case-study.png'}
                  className='action-img mt-3'
                  alt=''
                />
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
