import React, { useEffect, useState } from "react";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Card from "../components/card";
import BuildTerminal from "../components/page/buldterminal";
import Modal from "../components/system/modal";
import Nav from "../components/system/nav";
import Table from "../components/system/table";

function Settings() {
  let [featuredBlog, setFeaturedBlog] = useState({});
  let [featuredCaseStudy, setFeaturedCaseStudy] = useState({});
  let [featuredCareer, setFeaturedCareer] = useState({});

  let [featuredModal, showFeaturedModal] = useState(false);
  let [publishModal, showPublishModal] = useState(false);
  let [loading, setLoading] = useState(false);

  let [query, setQuery] = useState({});
  let [data, setData] = useState();
  let [unpublished, setUnpublished] = useState([]);

  let [publishable, setPublishable] = useState([]);

  let fetchPages = async ($query) => {
    setLoading(true);

    return await window.app
      .service("pages")
      .find({
        query: {
          ...query,
          ...$query,
          status: {
            $gt: 0,
          },
        },
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        console.error(err);
      });

    // console.log(filter);
  };

  let updateFeaturedPage = (id, newId) => {
    if (id)
      window.app
        .service("pages")
        .patch(id, {
          featured: false,
        })
        .then((response) => {
          console.log("patched old");
        })
        .catch((err) => {
          console.error(err);
        });

    if (newId)
      window.app
        .service("pages")
        .patch(newId, {
          featured: true,
        })
        .then((response) => {
          console.log("patched new");
        })
        .catch((err) => {
          console.error(err);
        });
  };

  let chooseFeatured = (data, type, update) => {
    switch (type) {
      case 2:
        if (update) updateFeaturedPage(featuredCareer.id, data.id);
        setFeaturedCaseStudy(data);
        break;
      case 3:
        if (update) updateFeaturedPage(featuredBlog.id, data.id);
        setFeaturedBlog(data);
        break;
      case 4:
        if (update) updateFeaturedPage(featuredCareer.id, data.id);
        setFeaturedCareer(data);
        break;
      default:
        break;
    }
  };

  let getFeaturedPages = () => {
    window.app
      .service("pages")
      .find({
        query: {
          featured: true,
        },
      })
      .then((response) => {
        response.data.map((d) => {
          chooseFeatured(d, d.type);
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let getUnpublishedPages = () => {
    window.app
      .service("pages")
      .find({
        query: {
          $select: ["id", "name", "published", "status", "type"],
          $limit: 100,
          $or: [{ published: 0 }, { published: 1 }, { published: 3 }],
          status: {
            $gt: 0,
          },
        },
      })
      .then((response) => {
        setUnpublished(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    // fetch featured blog
    getFeaturedPages();
  }, []);

  let publish = () => {
    showPublishModal(false);

    window.app
      .service("publish")
      .create({})
      .then((response) => {})
      .catch((err) => {
        console.error(err);
      });
  };

  let updatePublish = (id, published) => {
    // console.log(id, published);
    window.app
      .service("pages")
      .patch(id, {
        published,
      })
      .then((response) => {
        getUnpublishedPages();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let restartDevServer = () => {
    showPublishModal(false);

    window.app
      .service("publish")
      .patch(1, {})
      .then((response) => {})
      .catch((err) => {
        console.error(err);
      });
  };

  let stopDevServer = () => {
    showPublishModal(false);

    window.app
      .service("publish")
      .remove(1)
      .then((response) => {})
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="">
      <Nav title={"Configuration"}></Nav>

      <div className="card page-card pt-4 px-3  mx-3 shadow-sm ">
        <div className="row m-0">
          <h5 className="label">Website publishing</h5>

          <div className="row mt-4">
            <div className="col-md-4">
              <button
                className="btn btn-primary d-inline-block btn-round"
                title="Publish all unpublished content to the live website"
                onClick={() => {
                  getUnpublishedPages();
                  showPublishModal(true);
                }}
              >
                Publish Website
              </button>
            </div>
            <div className="col-md-4">
              <button
                onClick={restartDevServer}
                className="btn btn-primary d-inline-block btn-round"
                title="Restart test server if server is not working properly"
              >
                Restart Test server
              </button>
            </div>
            <div className="col-md-4">
              <button
                onClick={stopDevServer}
                className="btn btn-danger d-inline-block btn-round text-white"
                title="Restart test server if server is not working properly"
              >
                Stop Test server
              </button>
            </div>
            <div className="col-md-4"></div>

            <div className="col-md-12">
              <BuildTerminal></BuildTerminal>
            </div>
          </div>
        </div>

        <div className="border-bottom my-5"></div>

        <div className="row m-0">
          <h5 className="label">Featured Items</h5>
          {/* featured blog */}
          <Card className="featured-item shadow-none m-2 px-0">
            {featuredBlog.id && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${window.stagingServer}/article${featuredBlog.uri}/${featuredBlog.id}`}
              >
                <img
                  src={window.fileserver + "sm-" + featuredBlog.data.banner}
                  alt=""
                  className="img-asset"
                />
                <div className="py-3 fw-bold">{featuredBlog.data.title}</div>
                {/* <div className='pb-3'>{featuredBlog.data.description}</div> */}
              </a>
            )}

            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="label m-0">| FEATURED BLOG </div>

              <span
                title="edit featured blog"
                className="action-icon"
                onClick={() => {
                  showFeaturedModal(true);
                  setQuery({ ...query, type: 3 });
                  fetchPages({ type: 3 });
                }}
              >
                <PencilSquare></PencilSquare>
              </span>
            </div>
          </Card>

          {/* featured career */}
          <Card className="featured-item shadow-none m-2 px-0">
            {featuredCareer.id && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${window.stagingServer}/article${featuredCareer.uri}/${featuredCareer.id}`}
              >
                <img
                  src={window.fileserver + "sm-" + featuredCareer.data.banner}
                  alt=""
                  className="img-asset"
                />
                <div className="py-3 fw-bold">{featuredCareer.data.title}</div>
                {/* <div className='pb-3'>{featuredBlog.data.description}</div> */}
              </a>
            )}

            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="label m-0">| FEATURED CAREER </div>

              <span
                title="edit featured blog"
                className="action-icon"
                onClick={() => {
                  showFeaturedModal(true);
                  setQuery({ ...query, type: 4 });
                  fetchPages({ type: 4 });
                }}
              >
                <PencilSquare></PencilSquare>
              </span>
            </div>
          </Card>

          {/* featured case study */}
          <Card className="featured-item shadow-none m-2 px-0">
            {featuredCaseStudy.id && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${window.stagingServer}/article${featuredCaseStudy.uri}/${featuredCaseStudy.id}`}
              >
                <img
                  src={
                    window.fileserver + "sm-" + featuredCaseStudy.data.banner
                  }
                  alt=""
                  className="img-asset"
                />
                <div className="py-3 fw-bold">
                  {featuredCaseStudy.data.title}
                </div>
                {/* <div className='pb-3'>{featuredBlog.data.description}</div> */}
              </a>
            )}

            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="label m-0">| FEATURED CASE STUDY </div>

              <span
                title="edit featured blog"
                className="action-icon"
                onClick={() => {
                  showFeaturedModal(true);
                  setQuery({ ...query, type: 2 });
                  fetchPages({ type: 2 });
                }}
              >
                <PencilSquare></PencilSquare>
              </span>
            </div>
          </Card>
        </div>
      </div>
      <Modal
        title={"Choose Page"}
        isActive={featuredModal}
        close={showFeaturedModal}
      >
        <div className="featured-table">
          <Table
            search={["name"]}
            data={data}
            fetch={fetchPages}
            loading={loading}
            entry={(data) => ({
              // id: data.id,
              name: data.name,

              "": (
                <div className="flex-row">
                  <button
                    to={`/blog${data.uri}/${data.id}`}
                    className="btn btn-outline-primary btn-sm btn-round me-3 "
                    onClick={() => {
                      chooseFeatured(data, query.type, true);
                      console.log(data);
                      showFeaturedModal(false);
                    }}
                  >
                    Choose
                  </button>
                </div>
              ),
            })}
          ></Table>
        </div>
      </Modal>

      <Modal title={"Publish"} isActive={publishModal} close={showPublishModal}>
        <div className="label">Select pages to publish</div>

        <ul class="list-group mt-3">
          <label class="list-group-item list-group-item-action">
            <div className="d-flex flex-row align-items-center ">
              <input
                className="form-check-input mt-0 me-4"
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked) {
                    unpublished.map((d) => {
                      updatePublish(d.id, 1);
                    });
                  } else {
                    unpublished.map((d) => {
                      updatePublish(d.id, 3);
                    });
                  }
                }}
              />

              <div className="label">Page name</div>
            </div>
          </label>

          {unpublished.map((d, i) => (
            <label class="list-group-item list-group-item-action" key={i}>
              <div className="d-flex flex-row align-items-center ">
                <input
                  className="form-check-input mt-0 me-4"
                  type="checkbox"
                  onChange={() => {
                    let status = d.published === 0 || d.published === 3 ? 1 : 3;
                    updatePublish(d.id, status);
                  }}
                  checked={d.published === 1}
                />

                <div>{d.name}</div>
              </div>
            </label>
          ))}
        </ul>

        <button className="btn btn-primary mt-4" onClick={publish}>
          Publish Website Now
        </button>
      </Modal>
    </div>
  );
}

export default Settings;
