import React from 'react';

function Progress({ progress }) {
  return (
    <div>
      <h4 className='text-cente text-white'>{Math.round(progress)}%</h4>
      <div class='progress' style={{ width: 100 }}>
        <div
          class='progress-bar progress-bar-striped bg-primary progress-bar-animated'
          role='progressbar'
          style={{ width: progress + '%' }}
          aria-valuenow={progress}
          aria-valuemin='0'
          aria-valuemax='100'></div>
      </div>
    </div>
  );
}

export default Progress;
