import React from 'react';
import Nav from '../components/system/nav';
import PageEditor from '../components/page/basicEditor';
import Form from '../components/system/form';
import Card from '../components/card';
function PageEdit() {
  return (
    <div className=''>
      <Nav
        title='Edit Page'
        links={[
          {
            text: 'Save',
            onclick: () => {},
            className: 'btn-outline-info',
          },
          {
            text: 'Preview',
            onclick: () => {},
            className: 'btn-outline-primary',
          },
        ]}></Nav>

      <div className='row px-4'>
        <div className='col-md-9 '>
          <div className='card mb-4'>
            <PageEditor></PageEditor>
          </div>
          <Card title='SEO'>
            <Form
              onChange={(inputs) => {
                console.log(inputs);
              }}
              inputs={[
                {
                  label: 'SEO title',
                  key: 'seo_title',
                  required: true,
                },
                {
                  label: 'SEO description',
                  key: 'seo_description',
                  type: 'textarea',
                  required: true,
                },
              ]}></Form>
          </Card>
        </div>

        <div className='col-md-3'>
          <Card title='PAGE SETTINGS'>
            <Form
              inputs={[
                {
                  label: 'Page Name',
                  key: 'name',
                  required: true,
                },
                {
                  label: 'Page URL',
                  key: 'url',
                  required: true,
                },
              ]}></Form>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default PageEdit;
