import React, { useEffect, useState } from 'react';
import {
  Calendar2Check,
  Calendar3,
  ClockHistory,
  FileEarmarkBarGraph,
  FileEarmarkRichtext,
  Gear,
  Grid1x2,
  HouseDoor,
  JournalRichtext,
  PeopleFill,
  PersonBadge,
  PersonCheck,
  PersonLinesFill,
} from 'react-bootstrap-icons';
import { Route, Routes } from 'react-router-dom';
import Layout from '../layout';
import Home from './home';
import Pages from './pages';
import Page from './page_edit';

import feathers from '@feathersjs/client';
import Alert from '../components/alert';
import PageEdit from './page_edit';
import PageHome from './page_home';
import CaseStudies from './page_case_studies';
import PageEditCaseTudy from './page_edit_case_study';
import Blogs from './blogs';
import BlogEdit from './blog_edit';
import AboutEdit from './about_edit';
import TeamEdit from './team_edit';
import Careers from './careers';
import CareerEdit from './career_edit';
import Users from './users';
import TestimonialEdit from './testimonial_edit';
import Settings from './settings';
import Loader from '../components/system/loader';
import ServicesEdit from './services_edit';

const rest = require('@feathersjs/rest-client');

const io = require('socket.io-client');

let menu = [
  {
    name: 'Home',
    icon: <HouseDoor size={20} />,
    link: '/',
    access: 'all',
  },

  {
    name: 'Pages',
    icon: <FileEarmarkRichtext size={20} />,
    link: '/pages',
    access: 'all',
  },
  {
    name: 'Case Studies',
    icon: <Grid1x2 size={20} />,
    link: '/case-studies',
    access: 'all',
  },
  {
    name: 'Blogs',
    icon: <JournalRichtext size={20} />,
    link: '/blogs',
    access: 'all',
  },
  {
    name: 'Career',
    icon: <PersonCheck size={20} />,
    link: '/careers',
    access: 'all',
  },
  {
    name: 'Users',
    icon: <PeopleFill size={20} />,
    link: '/users',
    access: 'all',
  },
  {
    name: 'Configuration',
    icon: <Gear size={20} />,
    link: '/settings',
    access: 'all',
  },
];

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  window.server = 'http://localhost:3030';
  window.fileserver = 'http://localhost:3030/static/';
  window.stagingServer = 'http://localhost:8000';
} else {
  // production code
  window.server = 'https://api.tandem.network';
  window.fileserver = 'https://api.tandem.network/static/';
  window.stagingServer = 'https://beta.tandem.network';
}

const socket = io(window.server);

// configure rest api

window.app = feathers();
window.app.configure(
  feathers.socketio(socket, {
    timeout: 200000,
  })
);

window.app.configure(
  feathers.authentication({
    storage: window.localStorage,
  })
);

function Dashboard() {
  let [authorized, setAuthorized] = useState(false);
  let [loading, setLoading] = useState(true);

  let authenticate = () => {
    return window.app
      .reAuthenticate()
      .then((response) => {
        // window._app = feathers();
        // const restClient = rest(window.server);
        // window._app.configure(
        //   restClient.fetch(window.fetch, {
        //     Authorization: 'Bearer ' + response.accessToken,
        //   })
        // );

        window.accessToken = response.accessToken;

        localStorage.user = JSON.stringify(response.user);
        window.user = response.user;

        console.log('authorized');

        setAuthorized(true);
        setLoading(false);

        // fetchDefaults();

        let path = window.location.pathname;

        // console.log('path before ', path);

        // if (path.includes('authenticate') || path.includes('get-started'))
        //   return false;
        // console.log('path after ', path);
      })
      .catch((error) => {
        console.log('::NOT AUTHORIZED', error);

        setLoading(false);
        setAuthorized(false);

        if (!window.location.pathname.includes('/usr')) {
          window.location = '/usr/login';
        }
      });
  };

  let fetchDefaults = async () => {
    await window.app
      .service('permissions')
      .find({
        query: {
          $limit: 250,
          $skip: 0,
          userGroupId: window.user.userGroupId,
        },
      })
      .then((response) => {
        window.permissions = {};

        let data = [];
        response.data.map((d) => {
          window.permissions[
            d.permission_type.category.replace(/-/g, '_') +
              '_' +
              d.permission_type.name
          ] = d;
        });
      })
      .catch((err) => {
        console.error(err);
      });

    setLoading(false);
  };

  useEffect(() => {
    authenticate();
  }, []);

  return (
    <>
      <Loader loading={loading} className='floating-loader'></Loader>

      <Alert
        onRef={(ref) => {
          window.alert2 = ref;
          window.Alert = ref;
        }}></Alert>
      <div className='page-blur '>
        {authorized && (
          <Layout menu={menu}>
            <Routes>
              <Route exact path='/' element={<Home />} />
              <Route exact path='/pages' element={<Pages />} />
              <Route exact path='/page/edit/:id' element={<PageEdit />} />
              <Route exact path='/page//:id' element={<PageHome />} />
              <Route exact path='/page/about/:id' element={<AboutEdit />} />
              <Route exact path='/page/team/:id' element={<TeamEdit />} />
              <Route
                exact
                path='/page/services/:id'
                element={<ServicesEdit />}
              />
              <Route
                exact
                path='/page/testimonials/:id'
                element={<TestimonialEdit />}
              />
              <Route exact path='/case-studies' element={<CaseStudies />} />
              <Route
                exact
                path='/case-study/:uri/:id'
                element={<PageEditCaseTudy />}
              />
              <Route exact path='/blogs' element={<Blogs />} />
              <Route exact path='/blog/:uri/:id' element={<BlogEdit />} />

              <Route exact path='/careers' element={<Careers />} />
              <Route exact path='/career/:uri/:id' element={<CareerEdit />} />

              <Route exact path='/users' element={<Users />} />
              <Route exact path='/settings' element={<Settings />} />
            </Routes>
          </Layout>
        )}

        {loading && <div className='page-loader'></div>}
      </div>
    </>
  );
}

export default Dashboard;
