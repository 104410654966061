import React from 'react';

function Card({ title, children, className, onClick, buttons }) {
  return (
    <div
      className={'card ' + className}
      onClick={() => {
        if (onClick) onClick();
      }}>
      <div class='card-body'>
        <div className='d-flex flex-row justify-content-between '>
          {title && <h5 class='card-title'>{title}</h5>}

          <div className='d-flex flex-row align-items-center'>
            {buttons &&
              buttons.map((d) => (
                <span
                  className={'badge rounded-pill bg-primary btn ' + d.className}
                  onClick={() => {
                    if (d.onClick) d.onClick();
                  }}>
                  {d.label}
                </span>
              ))}
          </div>
        </div>

        {children}
      </div>
    </div>
  );
}

export default Card;
