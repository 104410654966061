import React, { useEffect, useState } from 'react';
import Nav from '../components/system/nav';
import PageEditor from '../components/page/basicEditor';
import Form from '../components/system/form';
import Card from '../components/card';
import { useParams } from 'react-router-dom';
import Media from '../components/media';
import BasicEdior from '../components/page/basicEditor';
import { Trash } from 'react-bootstrap-icons';

let previewWindow = null;

function TeamEdit() {
  let [data, setData] = useState({ data: {} });
  let [loading, setLoading] = useState(true);

  let { id, uri } = useParams();

  let save = () => {
    window.app
      .service('pages')
      .patch(id, data)
      .then((response) => {
        window.Alert.notify({
          title: 'Page Updated',
        });
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let fetchPage = () => {
    window.app
      .service('pages')
      .get(id)
      .then((response) => {
        console.log(response);
        setData(response);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchPage();
  }, []);

  let updateData = (inputs) => {
    let d = data;
    d.data = { ...data.data, ...inputs };

    setData(d);
  };
  return (
    <div className=''>
      <Nav
        title={`Edit Page`}
        links={[
          {
            text: 'Save',
            onClick: save,
            className: 'btn-primary',
          },
          {
            text: 'Preview',
            onClick: () => {
              if (!previewWindow || previewWindow.closed) {
                previewWindow = window.open(
                  `${window.stagingServer}/#team-1`,
                  'child-window'
                );
              }

              previewWindow.focus();
            },
            className: 'btn-outline-primary',
          },
        ]}></Nav>

      {!loading && (
        <div className='row px-4'>
          <div className='col-md-9 '>
            <Card className={'mb-4'}>
              <Form
                onChange={updateData}
                inputs={[
                  {
                    key: 'title_1',
                    required: true,
                    initialValue: data.data.title_1,
                    className: 'clean',
                    style: {
                      fontSize: 28,
                      fontFamily: '"Libre Baskerville", serif',
                      fontWeight: 'bold',
                    },
                  },
                ]}></Form>
            </Card>

            <h3 className='label'>Team members</h3>
            <TeamMembers
              members={data.data.members}
              onChange={updateData}></TeamMembers>

            <Card title='SEO'>
              <Form
                onChange={updateData}
                inputs={[
                  {
                    label: 'SEO title',
                    key: 'seo_title',
                    required: true,
                    initialValue: data.data.seo_title,
                  },
                  {
                    label: 'SEO description',
                    key: 'seo_description',
                    type: 'textarea',
                    required: true,
                    initialValue:
                      data.data.seo_description || data.data.intro_text,
                  },
                ]}></Form>
            </Card>
          </div>

          <div className='col-md-3'>
            <Card title='PAGE SETTINGS'>
              <Form
                onChange={(inputs) => {
                  setData({ ...data, ...inputs });
                }}
                inputs={[
                  {
                    label: 'Page Name',
                    key: 'name',
                    required: true,
                    initialValue: data.name,
                  },
                  {
                    label: 'Page URL',
                    key: 'uri',
                    disabled: true,
                    required: true,
                    initialValue: data.uri,
                  },
                ]}></Form>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
}

function TeamMembers(props) {
  let [members, setMembers] = useState(props.members || [{}, {}, {}]);

  useEffect(() => {
    props.onChange({ members });
  }, [members]);

  return (
    <div className=''>
      {members.map((member, i) => {
        return (
          <Card className={'mb-4'} key={i}>
            <div className='d-flex flex-row justify-content-end'>
              <div
                className='action-icon'
                onClick={(res) => {
                  let $members = [...members];

                  $members.splice(i, 1);
                  console.log($members);
                  setMembers($members);
                }}>
                {' '}
                <Trash></Trash> Delete{' '}
              </div>
            </div>

            <div className='row p-0'>
              <div className='col-md-6'>
                <Media
                  uri={member.uri}
                  onChange={(res) => {
                    let $members = [...members];
                    $members[i].uri = res.uri;
                    setMembers($members);
                  }}></Media>
              </div>

              <div className='col-md-6'>
                <div className='' key={i}>
                  <Form
                    onChange={(data) => {
                      let $members = members;
                      $members[i] = { ...$members[i], ...data };
                      setMembers($members);
                    }}
                    inputs={[
                      {
                        label: 'Name',
                        key: 'name',
                        required: true,
                        initialValue: member.name,
                      },
                      {
                        label: 'profile',
                        key: 'profile',
                        type: 'textarea',
                        required: true,
                        initialValue: member.profile,
                      },
                    ]}></Form>
                </div>
              </div>

              <div className='col-md-12'>
                <TeamFlex
                  flexes={member.flexes}
                  onChange={({ flexes }) => {
                    let $members = members;
                    $members[i].flexes = flexes;
                    setMembers($members);
                  }}></TeamFlex>
              </div>
            </div>
          </Card>
        );
      })}

      <button
        className='btn btn-outline-primary w-100 mb-4'
        onClick={() => {
          setMembers([...members, {}]);
        }}>
        Add a team member
      </button>
    </div>
  );
}

function TeamFlex(props) {
  let [flexes, setflexes] = useState(props.flexes || [{}, {}, {}]);

  return (
    <div className='row'>
      {flexes.map((flex, i) => {
        return (
          <div className='col-md-4' key={i}>
            <Form
              onChange={(data) => {
                let $flexes = [...flexes];
                $flexes[i] = data;
                setflexes($flexes);
                props.onChange({ flexes: $flexes });
              }}
              inputs={[
                {
                  label: 'Total',
                  key: 'total',
                  required: true,
                  initialValue: flex.total,
                },
                {
                  label: 'description',
                  key: 'description',
                  type: 'textarea',
                  required: true,
                  initialValue: flex.description,
                },
              ]}></Form>
          </div>
        );
      })}
    </div>
  );
}

export default TeamEdit;
