import { Terminal } from 'xterm';
import 'xterm/css/xterm.css';

import React, { useEffect, useState } from 'react';

let terminalInit = false;
let term;
let end = true;

function BuildTerminal() {
  let [active, setActive] = useState(false);

  useEffect(() => {
    if (!terminalInit) {
      terminalInit = true;

      window.app.service('publish').on('message', ({ message }) => {
        console.log(message);
        setActive(true);

        term.write(message);
        // setTimeout(() => {
        //   document.querySelector('#terminal-footer').scrollIntoView();
        // }, 300);
      });

      window.app.service('publish').on('active', (data) => {
        setActive(data.active);

        if (!data.active && end) {
          end = false;
          window.alert2.notify({
            title: 'Publish complete',
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (active) {
      term = new Terminal();
      term.open(document.getElementById('terminal'));

      term.write('Inititalizing Bundling ...');
    }
  }, [active]);

  return (
    <>
      {active && (
        <div
          className={'mt-4 terminal ' + (active && 'active')}
          id='terminal'></div>
      )}
    </>
  );
}

export default BuildTerminal;
