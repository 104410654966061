import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DasboardEditor from './editor';
import './css/style.scss';
import Dashboard from './dashboard';
import Login from './dashboard/login';

function App() {
  return (
    <div>
      {/* initialize dashboard editor route */}
      {/* <Router basename='god_mode'>
        <DasboardEditor></DasboardEditor>
      </Router> */}
      <Router basename='usr'>
        <Routes>
          <Route exact path='/login' element={<Login />} />
        </Routes>
      </Router>
      <Router>
        <Dashboard></Dashboard>
      </Router>
    </div>
  );
}

export default App;
