import React, { useState } from 'react';
import Input from './input';

function Form({ onSubmit, inputs = {}, onChange, submit }) {
  let [value, setValue] = useState({});

  return (
    <form
      className='row'
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(value);
      }}>
      {inputs.map((input, i) => (
        <Input
          {...input}
          key={i}
          onChange={(data) => {
            let val = value;
            val[input.key] = data;
            setValue(val);
            if (onChange) onChange(value);
          }}
        />
      ))}

      {submit && (
        <div className='p-3'>
          <button className='btn btn-primary btn-round w-100 mt-2'>
            {submit}
          </button>
        </div>
      )}
    </form>
  );
}

export default Form;
