import React, { Component } from 'react';
import { Diagram2, HouseDoor, LayoutWtf } from 'react-bootstrap-icons';
import Menu from './components/system/menu';
import logo from './img/logo_black.svg';

function Layout({ menu, children }) {
  // Dashboard editor menu

  return (
    <div className='app bg-light'>
      <Menu menu={menu} navLogo={logo}></Menu>

      <div className='app-main'>{children}</div>
    </div>
  );
}

export default Layout;
