import React, { useState } from 'react';
import { PlusCircle, Trash } from 'react-bootstrap-icons';
import Form from '../system/form';

function StatsCard2(props) {
  let [slides, setSlides] = useState(props.slides || [{}]);

  return (
    <div className='' contentEditable={false}>
      <div className='row align-items-center stats-card-2 m-0 shadow-sm'>
        {slides &&
          slides.map((slide, i) => (
            <div key={i} className='col-md-3  edit-parent '>
              <div className='edit-menu shadow-sm px-3 text-dark'>
                <div
                  className='action-icon'
                  onClick={() => {
                    let $slides = [...slides];
                    $slides.splice(i, 1);
                    setSlides($slides);
                  }}>
                  <Trash></Trash>{' '}
                  <span>
                    {' '}
                    <small> Delete</small>
                  </span>
                </div>
              </div>

              <Form
                onChange={(data) => {
                  let $slides = [...slides];
                  $slides[i] = { ...$slides[i], ...data };
                  setSlides($slides);
                  console.log(data);

                  props.onChange({ slides: $slides });
                }}
                inputs={[
                  {
                    label: 'Count',
                    key: 'count',
                    required: true,
                    initialValue: slide.count,
                  },
                  {
                    label: 'Description',
                    key: 'description',
                    type: 'textarea',
                    required: true,
                    initialValue: slide.description,
                  },
                ]}></Form>
            </div>
          ))}

        <div className='col-md-3'>
          <div
            className=' media media-icon action-icon mb-3 py-3'
            onClick={() => {
              let $slides = [...slides];
              $slides.push({});
              setSlides($slides);
            }}>
            <PlusCircle size={40}></PlusCircle>

            <div className='mt-2 label'>Add item</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatsCard2;
