import React from 'react';

function SlateRichText({ children, atributes }) {
  return (
    <div className='p-3 state-richtext' {...atributes}>
      {children}
    </div>
  );
}

export default SlateRichText;
