import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import loader from '../../img/lottie/loader.json';

function Loader(props) {
  let [loading, setLoading] = useState(props.loading);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  return (
    loading && (
      <div className={`loader ${props.className}`}>
        <div className='loader-container'>
          <Lottie animationData={loader} loop={true}></Lottie>
        </div>
      </div>
    )
  );
}

export default Loader;
