import React, { Component } from 'react';
import { XCircle } from 'react-bootstrap-icons';
// import { CheckCircle, X } from 'react-feather';

class Alert extends Component {
  state = {
    active: false,
    loader: false,
    icon: null,
    title: null,
    message: null,
    close: null,
    notifications: [],
    confirmText: null,
    buttons: null,
  };
  render() {
    return (
      <>
        <div className={`alert ${!this.state.active || 'active'}`}>
          <div className='content bg-white  shadow-lg text-center d-flex flex-column align-items-center justify-content-center'>
            {this.state.loader && (
              <div className='alert-loader px-3 my-3 d-flex flex-row justify-content-center'>
                <div className='nest-parent'>
                  <div id='nest1'></div>
                </div>
              </div>
            )}

            {this.state.icon && (
              <div className='px-3 mb-3 my-3 text-primary d-flex flex d-flex flex-row justify-content-center'>
                {this.state.icon}
              </div>
            )}
            {this.state.title && (
              <div className='alert-title px-3 my-3'>{this.state.title}</div>
            )}
            {this.state.message && (
              <div className='alert-message px-3 my-3'>
                {this.state.message}
              </div>
            )}
            {this.state.buttons !== false && (
              <div
                className={`alert-footer px-3 mb-3 my-3 w-100 mt-3 py-3 d-flex flex-row justify-content-${
                  this.state.close ? 'between' : 'center'
                }`}>
                {this.state.close && (
                  <button
                    type='button'
                    className='btn btn-outline-primary btn-round px-3'
                    onClick={() => {
                      this.setState({ active: false });
                    }}>
                    Cancel
                  </button>
                )}

                {this.state.buttons !== false && (
                  <button
                    type='button'
                    className='btn btn-primary btn-round px-3'
                    onClick={() => {
                      this.setState({ active: false });
                      // if (this.onConfirm) this.onConfirm();
                      if (this.state.onSubmit) this.state.onSubmit();
                    }}>
                    {this.state.confirmText ? this.state.confirmText : 'Ok'}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>

        <div className={`notification-container `}>
          {this.state.notifications.map((d, i) => (
            <div
              className={`popup p-2 ${
                !d.active || 'show'
              } position-relative pb-5`}
              key={i}>
              <div
                className={` popup-inner p-3 text-white ${d.color} shadow-lg `}>
                <div
                  className='icon-hover notification-close position-absolute'
                  onClick={() => this.closeNotification(i)}>
                  {' '}
                  <XCircle size={22}></XCircle>
                </div>
                {d.title && (
                  <div className='d-flex flex-row align-items-center mb-3 justify-content-between'>
                    <h5 className='font-weight-bold m-0'>{d.title}</h5>
                  </div>
                )}

                {d.description && (
                  <div className='popup-message my-3'>{d.description}</div>
                )}
                <div>
                  {d.buttons !== false && (
                    <div
                      className='btn btn-outline-light btn-round px-3'
                      onClick={() => {
                        this.closeNotification(i);
                        if (d.onSubmit) d.onSubmit();
                      }}>
                      {d.buttonText || 'Ok'}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }

  componentDidMount() {
    window.customAlert = (callback) => {
      this.setState({ active: true });
    };
    this.props.onRef(this);
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.active !== prevState.active) {
      // this.props.toggle(this.state.active);
      if (this.state.active) {
        document.querySelector('.page-blur').classList.add('blur-alert');
      } else {
        document.querySelector('.page-blur').classList.remove('blur-alert');
      }

      if (!this.state.active) {
        if (this.onClose) this.onClose();

        // garbage collection
        setTimeout(() => {
          this.reset();
          delete this.onClose;
          delete this.onConfirm;
        }, 300);
      }
    }
  };

  show = (opts) => {
    this.setState({ active: true, ...opts });
  };

  hide = () => {
    this.setState({ active: false });
  };

  confirm = (options) => {
    this.setState({ active: true, close: true, ...options });
  };

  reset = () => {
    this.setState({
      active: false,
      loader: false,
      icon: null,
      title: null,
      message: null,
      close: null,
      buttons: null,
      onSubmit: null,
    });
  };

  notify = (opts) => {
    let { notifications } = this.state;

    let config = {
      autoClose: true,
      color: 'bg-primary',
      autoCloseDuration: 3000,
      ...opts,
    };

    notifications.push(config);
    this.setState({ notifications });
    let index = notifications.length - 1;

    setTimeout(() => {
      let { notifications } = this.state;
      notifications[index].active = true;
      this.setState({ notifications });
    }, 50);

    if (config.autoClose) {
      setTimeout(() => {
        this.closeNotification(index);
      }, config.autoCloseDuration);
    }
  };

  closeNotification = (i) => {
    try {
      let { notifications } = this.state;
      notifications[i].active = false;

      this.setState({ notifications });

      setTimeout(() => {
        delete notifications[i];
        console.log(notifications);
        this.setState({ notifications });
      }, 500);
    } catch (err) {}
  };
}

export default Alert;
