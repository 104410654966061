import React from 'react';
import { XCircle } from 'react-bootstrap-icons';

function Modal({ title, isActive, children, close, className }) {
  return (
    <div
      className={
        'modal fade ' + (isActive && 'show d-block ') + ' ' + className
      }>
      <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
        <div className='modal-content'>
          <div className='modal-header border-0'>
            {title && <h5 className='modal-title title'>{title}</h5>}

            <div
              className='action-icon'
              aria-label='Close'
              onClick={(e) => {
                e.preventDefault();
                close(false);
              }}>
              <XCircle></XCircle>
            </div>
          </div>
          <div className='modal-body pt-0'>{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
