import React, { useEffect, useState } from 'react';
import Nav from '../components/system/nav';
import PageEditor from '../components/page/basicEditor';
import Form from '../components/system/form';
import Card from '../components/card';
import { useParams } from 'react-router-dom';
import Swiper from '../components/swiper';
import BasicEdior from '../components/page/basicEditor';
import Media from '../components/media';
import {
  ArrowDownCircle,
  ArrowUpCircle,
  Trash,
  XCircle,
} from 'react-bootstrap-icons';

let previewWindow = null;

function ServicesEdit() {
  let [data, setData] = useState({ data: {} });
  let [loading, setLoading] = useState(true);
  let [categories, setCategories] = useState([]);

  let categoryName;

  let { id, uri } = useParams();

  let save = () => {
    console.log('save', data);
    window.app
      .service('pages')
      .patch(id, data)
      .then((response) => {
        window.Alert.notify({
          title: 'Page Updated',
        });

        console.log('save', response);

        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let fetchPage = () => {
    window.app
      .service('pages')
      .get(id)
      .then((response) => {
        console.log('page ', response);
        setData(response);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let fetchCategories = () => {
    window.app
      .service('page-category')
      .find({
        query: {
          status: 2,
        },
      })
      .then((response) => {
        console.log('page categories ', response);
        setCategories(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchPage();
    fetchCategories();
  }, []);

  let updateData = (inputs, i) => {
    let d = data;
    d.data = { ...data.data, ...inputs };

    if (!d.data.seo_title || !d.data.seo_description) {
      d.data.seo_title = d.title;
      d.data.seo_description = d.description;
    }

    setData(d);

    // console.log(data);
  };

  let createCategory = () => {
    console.log(categoryName);
    window.app
      .service('page-category')
      .create({
        name: categoryName,
        status: 2,
      })
      .then((response) => {
        let article = { ...data };
        article.pageCategoryId = response.id;
        categoryName = '';

        let $categories = [...categories];
        $categories.push(response);
        setCategories($categories);

        setData(article);
      });
  };

  return (
    <div className=''>
      <Nav title={`Edit ${data.name}`}></Nav>

      {!loading && (
        <div className='row px-4'>
          <div className='col-md-9 '>
            <Card className={'mb-4'}>
              {data.data.services &&
                data.data.services.map((service, i) => (
                  <div className='row py-3 border-bottom' key={i}>
                    <div className='position-relative'>
                      <Form
                        onChange={(inputs) => {
                          let $data = { ...data };
                          $data.data.services[i] = {
                            ...$data.data.services[i],
                            ...inputs,
                          };
                          setData($data);
                        }}
                        inputs={[
                          {
                            label: 'title',
                            key: 'name',
                            required: true,
                            initialValue: service.name,
                            size: 'col-md-5',
                          },

                          {
                            label: 'Menu Label',
                            key: 'label',
                            required: true,
                            initialValue: service.label,
                            size: 'col-md-5',
                          },
                          {
                            label: 'Description',
                            key: 'description',
                            required: true,
                            initialValue: service.description,
                            size: 'col-md-12',
                            type: 'textarea',
                          },
                        ]}></Form>

                      <div
                        className='d-flex align-items-center'
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 30,
                        }}>
                        <button
                          title='remove service'
                          className='btn btn-outline-danger btn-sm'
                          onClick={() => {
                            let $data = { ...data };
                            $data.data.services.splice(i, 1);
                            setData($data);
                          }}>
                          {' '}
                          <Trash></Trash> Remove{' '}
                        </button>

                        <div className='d-flex ms-2'>
                          <div
                            disabled={i === 0}
                            title='move up'
                            className='action-icon btn-sm'
                            onClick={() => {
                              let $data = { ...data };
                              let temp = JSON.parse(
                                JSON.stringify($data.data.services[i])
                              );
                              // check if not first
                              if (i === 0) return;

                              $data.data.services[i] = JSON.parse(
                                JSON.stringify($data.data.services[i - 1])
                              );
                              $data.data.services[i - 1] = temp;
                              setData($data);
                            }}>
                            {' '}
                            {/* bs5 icon */}
                            <ArrowUpCircle size={17}></ArrowUpCircle>
                          </div>
                          <div
                            title='move down'
                            className='action-icon btn-sm'
                            onClick={() => {
                              let $data = { ...data };
                              let temp = $data.data.services[i];
                              $data.data.services[i] =
                                $data.data.services[i + 1];
                              $data.data.services[i + 1] = temp;
                              setData($data);
                            }}>
                            {' '}
                            {/* bs5 icon */}
                            <ArrowDownCircle size={17}></ArrowDownCircle>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='row'>
                        {service.options.map((option, option_index) => {
                          return (
                            <div className='col-md-6 position-relative'>
                              <div
                                title='remove option'
                                className='action-icon '
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  right: 0,
                                }}
                                onClick={() => {
                                  let $data = { ...data };
                                  $data.data.services[i].options.splice(
                                    option_index,
                                    1
                                  );
                                  setData($data);
                                }}>
                                <Trash></Trash>
                              </div>

                              <Form
                                onChange={(inputs) => {
                                  let $data = { ...data };
                                  $data.data.services[i].options[option_index] =
                                    {
                                      ...$data.data.services[i].options[
                                        option_index
                                      ],
                                      ...inputs,
                                    };
                                  setData($data);
                                }}
                                inputs={[
                                  {
                                    label: option_index + 1 + '. Name',
                                    key: 'name',
                                    required: true,
                                    initialValue: option.name,
                                  },
                                  {
                                    label: 'Description',
                                    key: 'description',
                                    required: true,
                                    type: 'textarea',
                                    initialValue: option.description,
                                  },
                                ]}></Form>
                            </div>
                          );
                        })}
                      </div>
                      <div className='mt-3'>
                        <button
                          className='btn btn-outline-primary btn-sm'
                          onClick={() => {
                            let $data = { ...data };
                            $data.data.services[i].options.push({
                              name: '',
                              description: '',
                            });
                            setData($data);
                          }}>
                          Add Option
                        </button>
                      </div>
                    </div>
                  </div>
                ))}

              <div className='mt-3'>
                <button
                  className='btn btn-outline-primary'
                  onClick={() => {
                    let $data = { ...data };
                    if (!$data.data.services) {
                      $data.data = {};
                      $data.data.services = [];
                    }
                    $data.data.services.push({
                      name: '',
                      label: '',
                      options: [
                        {
                          name: '',
                          description: '',
                        },
                      ],
                    });
                    setData($data);
                  }}>
                  Add Service
                </button>
              </div>
            </Card>
          </div>

          <div className='col-md-3 position-relative'>
            <Card title='PAGE SETTINGS' className='sticky-card'>
              <Form
                onChange={(inputs) => {
                  setData({ ...data, ...inputs });
                }}
                inputs={[
                  {
                    label: 'Page Name',
                    key: 'name',
                    required: true,
                    initialValue: data.name,
                  },
                  {
                    label: 'Page URL',
                    key: 'uri',
                    disabled: true,
                    required: true,
                    initialValue: data.uri,
                  },
                ]}></Form>

              {/* <label className='label'>category</label>
              {categories.length > 0 && (
                <select
                  className='form-control'
                  value={data.pageCategoryId}
                  onChange={(e) => {
                    if (e.target.value == 0) {
                      window.alert2.show({
                        title: 'Add Category',
                        message: (
                          <div>
                            <div class='form-group mb-0'>
                              <input
                                onChange={(e) => {
                                  console.log(e.target.value);
                                  categoryName = e.target.value;
                                }}
                                type='text'
                                required={true}
                                class='form-control'
                                placeholder='Enter Category'
                              />
                            </div>
                          </div>
                        ),
                        close: true,
                        onSubmit: () => {
                          createCategory();
                        },
                      });

                      return false;
                    }

                    let article = { ...data };
                    article.pageCategoryId = e.target.value;
                    setData(article);
                  }}>
                  <option value='' hidden selected>
                    Choose Category
                  </option>
                  {categories.map((d) => (
                    <option value={d.id}>{d.name}</option>
                  ))}
                  <option value='0'>Other</option>
                </select>
              )} */}

              <label className='d-flex flex-row align-items-center mt-3'>
                <input
                  className='form-check-input mt-0 me-3'
                  type='checkbox'
                  checked={data.published === 1}
                  onChange={(e) => {
                    let $data = { ...data };
                    $data.published = e.target.checked ? 1 : 3;

                    console.log($data.published);
                    setData($data);
                  }}
                />

                <div className='label m-0'>Publish</div>
              </label>
              <div className='row mt-3'>
                <div className='col-md-6'>
                  <button
                    className='btn btn-outline-primary w-100'
                    onClick={save}>
                    Save
                  </button>
                </div>
                <div className='col-md-6'>
                  <button
                    className='btn btn-outline-primary w-100'
                    onClick={() => {
                      if (!previewWindow || previewWindow.closed) {
                        previewWindow = window.open(
                          `${window.stagingServer}#service-0`,
                          'blog-window'
                        );
                      }

                      previewWindow.focus();
                    }}>
                    Preview
                  </button>
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
}

export default ServicesEdit;
