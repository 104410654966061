import React, { useState } from 'react';
import Media from '../media';
import SlateRichText from './slateRichtext';

function TextSection(props) {
  let [uri, setURI] = useState(props.uri);
  let [content, setContent] = useState(props.content);

  return (
    <div className='row border-top border-bottom py-3 align-items-center'>
      <div className='col-md-6' contentEditable={false}>
        <Media
          uri={uri}
          onChange={({ uri }) => {
            setURI(uri);
            props.onChange({ uri, content });
          }}></Media>
      </div>
      <div className='col-md-6' {...props.attributes}>
        <SlateRichText>{props.children}</SlateRichText>
      </div>
    </div>
  );
}

export default TextSection;
