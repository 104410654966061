import React, { useEffect, useState } from 'react';
import { CloudUpload, Image } from 'react-bootstrap-icons';
import ReactPaginate from 'react-paginate';
import ReactPlayer from 'react-player/youtube';
import Progress from './progress';
import Input from './system/input';
import Modal from './system/modal';

function Media({ children, onChange, uri }) {
  let [modalStatus, setModalStatus] = useState(false);
  let [activeTab, setActiveTab] = useState('images');
  let [assets, setAssets] = useState([]);
  let [url, setURL] = useState(uri);
  let [youtubeURL, setYoutubeURL] = useState();

  let [$skip, setSkip] = useState(0);
  let [total, setTtotal] = useState(0);

  useEffect(() => {
    if (uri !== url) setURL(uri);
  }, [uri]);

  let upload = (file, index) => {
    let formdata = new FormData();

    formdata.append('name', file.name);
    formdata.append('uri', file);

    let request = new XMLHttpRequest();
    let file1Size = file.size;

    request.upload.addEventListener('progress', function (e) {
      let files = [...assets];

      if (e.loaded <= file1Size) {
        let percent = Math.round((e.loaded / file1Size) * 100);
        console.log(percent, '%');
        files[index].progress = percent;
        setAssets(files);
      }

      if (e.loaded == e.total) {
        files[index].progress = false;
        setAssets(files);
      }
    });

    request.onreadystatechange = function () {
      if (request.readyState === 4) {
        // console.log(request.response);
        fetchMedia();
      }
    };

    request.open('post', window.server + '/media');
    request.setRequestHeader('Authorization', 'Bearer ' + window.accessToken);
    request.timeout = 45000;
    request.send(formdata);
  };

  let fetchMedia = () => {
    window.app
      .service('assets')
      .find({
        query: {
          $skip,
          $sort: {
            createdAt: -1,
          },
          $limit: 9,
        },
      })
      .then((response) => {
        setTtotal(response.total);
        setAssets(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // fetchAssets
  useEffect(() => {
    fetchMedia();
  }, []);

  useEffect(() => {
    fetchMedia();
  }, [$skip]);

  return (
    <div contentEditable={false} style={{ userSelect: 'none' }}>
      <div
        className='media'
        onClick={(e) => {
          e.preventDefault();
          setModalStatus(true);
        }}>
        {!url && (
          <div className='py-5 text-center'>
            <Image size={50} className=''></Image>
            <div className='label'>Add Image</div>
          </div>
        )}

        {url &&
          (url.includes('youtube.com') || url.includes('youtu.be') ? (
            <ReactPlayer url={url} />
          ) : (
            <img
              src={window.fileserver + 'md-' + url}
              alt=''
              className='img-asset'
            />
          ))}
      </div>
      <Modal
        isActive={modalStatus}
        close={setModalStatus}
        title='Media'
        className='media-modal'>
        <label className='btn btn-primary media-add'>
          <input
            type='file'
            className='d-none'
            accept='image/*'
            onChange={(e) => {
              e.preventDefault();
              if (e.target.files.length) {
                for (let i = 0; i < e.target.files.length; i++) {
                  let file = e.target.files.item(i);
                  console.log(file.name);

                  let files = [...assets];

                  files.unshift({
                    file: file,
                    name: file.name,
                    progress: 1,
                  });

                  setAssets(files);
                  setTimeout(() => {
                    upload(file, 0);
                  }, 0);
                }
              }
            }}
          />
          <CloudUpload size={19} className='me-1'></CloudUpload> Add Files
        </label>

        <ul class='nav nav-tabs '>
          <li class='nav-item'>
            <div
              class={'nav-link ' + (activeTab === 'images' && 'active')}
              aria-current='page'
              onClick={() => setActiveTab('images')}>
              Images
            </div>
          </li>
          <li class='nav-item'>
            <div
              class={'nav-link ' + (activeTab === 'video' && 'active')}
              onClick={() => setActiveTab('video')}>
              Youtube Video
            </div>
          </li>
        </ul>

        {activeTab === 'images' && (
          <div>
            <div className='row pt-3'>
              {assets.map((d, i) => (
                <div className='col-md-4 my-3' key={i}>
                  <div className='media-image-parent'>
                    {d.progress && d.progress !== false && (
                      <div className='progress-overlay'>
                        <Progress progress={d.progress}></Progress>
                        <small>
                          {' '}
                          <small className='text-white'>Uploading ...</small>
                        </small>
                      </div>
                    )}

                    <img
                      src={
                        d.file
                          ? URL.createObjectURL(d.file)
                          : window.fileserver + 'sm-' + d.uri
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        if (onChange) onChange({ uri: d.uri });
                        setModalStatus(false);
                        setURL(d.uri);
                      }}
                      className='media-image'
                      alt=''
                    />
                  </div>

                  <label className='media-name label mt-2'>{d.name}</label>
                </div>
              ))}

              <ReactPaginate
                nextLabel='next >'
                onPageChange={(event) => setSkip(event.selected * 9)}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={Math.ceil(total / 9)}
                previousLabel='< previous'
                pageClassName='page-item'
                pageLinkClassName='page-link'
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextClassName='page-item'
                nextLinkClassName='page-link'
                breakLabel='...'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                containerClassName='pagination'
                activeClassName='active'
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        )}

        {activeTab === 'video' && (
          <div className='youtube-media py-5'>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                console.log('youtubeURL', youtubeURL);

                setURL(youtubeURL);

                if (onChange) onChange({ uri: youtubeURL });

                setModalStatus(false);
              }}>
              <Input
                type={'url'}
                label='enter youtube url'
                onChange={(youtubeurl) => {
                  setYoutubeURL(youtubeurl);
                }}></Input>
              <div className='text-center'>
                <button className='btn btn-primary px-5'>Add</button>
              </div>
            </form>
          </div>
        )}
      </Modal>

      {children}
    </div>
  );
}

export default Media;
