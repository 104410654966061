import React, { useEffect, useState } from 'react';
import Nav from '../components/system/nav';
import Form from '../components/system/form';
import Card from '../components/card';
import { useParams } from 'react-router-dom';
import Media from '../components/media';
import { ArrowDown, ArrowUp, PlusCircle } from 'react-bootstrap-icons';

let previewWindow = null;

function TestimonialEdit() {
  let [data, setData] = useState({ data: { testimonial: [] } });
  let [loading, setLoading] = useState(true);

  let { id, uri } = useParams();

  let save = () => {
    window.app
      .service('pages')
      .patch(id, data)
      .then((response) => {
        window.Alert.notify({
          title: 'Page Updated',
        });
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let fetchPage = () => {
    window.app
      .service('pages')
      .get(id)
      .then((response) => {
        console.log(response);

        if (response.data.testimonial) setData(response);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchPage();
  }, []);

  let updateData = (inputs) => {
    let d = data;
    d.data = { ...data.data, ...inputs };

    setData(d);
  };
  return (
    <div className=''>
      <Nav title={`Edit Page`}></Nav>

      {!loading && (
        <div className='row px-4'>
          <div className='col-md-9 '>
            <Card className={'mb-4'}>
              <Form
                onChange={updateData}
                inputs={[
                  {
                    key: 'title_1',
                    required: true,
                    initialValue: data.data.title_1,
                    className: 'clean',
                    style: {
                      fontSize: 28,
                      fontFamily: '"Libre Baskerville", serif',
                      fontWeight: 'bold',
                    },
                  },
                ]}></Form>
            </Card>

            <h3 className='label'>Team Testimonial</h3>

            {data.data.testimonial &&
              data.data.testimonial.map((testimonial, i) => (
                <Card
                  className={'mb-4'}
                  key={i}
                  title={'.'}
                  buttons={[
                    // move up
                    {
                      label: <ArrowUp></ArrowUp>,
                      className: 'ms-2 bg-primary',
                      onClick: () => {
                        // check if first
                        if (i === 0) return;
                        let $data = { ...data };
                        let $testimonial = $data.data.testimonial[i];
                        $data.data.testimonial.splice(i, 1);
                        $data.data.testimonial.splice(i - 1, 0, $testimonial);
                        setData($data);
                      },
                    },
                    // move down
                    {
                      label: <ArrowDown></ArrowDown>,
                      className: 'ms-2 bg-primary',
                      onClick: () => {
                        // check if last
                        if (i === data.data.testimonial.length - 1) return;

                        let $data = { ...data };
                        let $testimonial = $data.data.testimonial[i];
                        $data.data.testimonial.splice(i, 1);
                        $data.data.testimonial.splice(i + 1, 0, $testimonial);
                        setData($data);
                      },
                    },

                    {
                      label: 'Delete',
                      className: 'ms-2 bg-danger',
                      onClick: () => {
                        let $data = { ...data };
                        $data.data.testimonial.splice(i, 1);
                        setData($data);
                      },
                    },
                  ]}>
                  <div className='row p-0'>
                    <div className='col-md-4'>
                      <div className='label'>Add Logo</div>

                      <Media
                        uri={testimonial.logo}
                        onChange={({ uri }) => {
                          let $data = { ...data };
                          $data.data.testimonial[i].logo = uri;
                          setData($data);
                        }}></Media>

                      <div className='label mt-4'>Add Second Logo</div>

                      <Media
                        uri={testimonial.logo2}
                        onChange={({ uri }) => {
                          let $data = { ...data };
                          $data.data.testimonial[i].logo2 = uri;
                          setData($data);
                        }}></Media>

                      <div className='label mt-4'>Add Flat Logo</div>

                      <Media
                        uri={testimonial.logo_flat}
                        onChange={({ uri }) => {
                          let $data = { ...data };
                          $data.data.testimonial[i].logo_flat = uri;
                          setData($data);
                        }}></Media>
                    </div>

                    <div className='col-md-6'>
                      <div className='' key={i}>
                        <Form
                          onChange={(inputs) => {
                            let $data = { ...data };
                            $data.data.testimonial[i] = {
                              ...$data.data.testimonial[i],
                              ...inputs,
                            };
                            setData($data);
                          }}
                          inputs={[
                            {
                              label: 'Testimonial',
                              key: 'testimonial',
                              type: 'textarea',
                              required: true,
                              initialValue: testimonial.testimonial,
                            },
                            {
                              label: 'Name',
                              key: 'name',
                              required: true,
                              initialValue: testimonial.name,
                            },
                            {
                              label: 'Position',
                              key: 'position',
                              required: true,
                              initialValue: testimonial.position,
                            },
                            {
                              label: 'Organisation',
                              key: 'organisation',
                              required: true,
                              initialValue: testimonial.organisation,
                            },
                          ]}></Form>
                      </div>
                    </div>
                  </div>
                </Card>
              ))}

            <button
              className='btn btn-outline-primary w-100 btn-sm my-5'
              onClick={() => {
                let $data = { ...data };
                console.log($data.data);

                $data.data.testimonial.push({});
                setData($data);
                console.log($data);
              }}>
              <PlusCircle></PlusCircle> ADD A TESTIMONIAL
            </button>

            <Card title='SEO'>
              <Form
                onChange={updateData}
                inputs={[
                  {
                    label: 'SEO title',
                    key: 'seo_title',
                    required: true,
                    initialValue: data.data.seo_title,
                  },
                  {
                    label: 'SEO description',
                    key: 'seo_description',
                    type: 'textarea',
                    required: true,
                    initialValue:
                      data.data.seo_description || data.data.intro_text,
                  },
                ]}></Form>
            </Card>
          </div>

          <div className='col-md-3 position-relative'>
            <Card title='PAGE SETTINGS' className='sticky-card'>
              <Form
                onChange={(inputs) => {
                  setData({ ...data, ...inputs });
                }}
                inputs={[
                  {
                    label: 'Page Name',
                    key: 'name',
                    required: true,
                    initialValue: data.name,
                  },
                  {
                    label: 'Page URL',
                    key: 'uri',
                    disabled: true,
                    required: true,
                    initialValue: data.uri,
                  },
                ]}></Form>

              <div className='row'>
                <div className='col-md-6'>
                  <button
                    className='btn btn-outline-primary w-100'
                    onClick={save}>
                    Save
                  </button>
                </div>
                <div className='col-md-6'>
                  <button
                    className='btn btn-outline-primary w-100'
                    onClick={() => {
                      if (!previewWindow || previewWindow.closed) {
                        previewWindow = window.open(
                          `${window.stagingServer}/#testimonials`,
                          'child-window'
                        );
                      }

                      previewWindow.focus();
                    }}>
                    Preview
                  </button>
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
}

export default TestimonialEdit;
