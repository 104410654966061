import React, { useEffect, useState } from 'react';
import Nav from '../components/system/nav';
import PageEditor from '../components/page/basicEditor';
import Form from '../components/system/form';
import Card from '../components/card';
import { useParams } from 'react-router-dom';
import Media from '../components/media';

let previewWindow = null;

function PageHome({ route }) {
  let [data, setData] = useState({ data: {} });
  let [loading, setLoading] = useState(true);

  let { id, uri } = useParams();

  let save = () => {
    window.app
      .service('pages')
      .patch(id, data)
      .then((response) => {
        window.Alert.notify({
          title: 'Page Updated',
        });
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let fetchPage = () => {
    window.app
      .service('pages')
      .get(id)
      .then((response) => {
        setData(response);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchPage();
  }, []);

  let updateData = (inputs) => {
    let d = data;
    d.data = { ...data.data, ...inputs };

    setData(d);
  };
  return (
    <div className=''>
      <Nav
        title={`Edit ${data.name}`}
        links={[
          {
            text: 'Save',
            onClick: save,
            className: 'btn-primary',
          },
          {
            text: 'Preview',
            onClick: () => {
              if (!previewWindow || previewWindow.closed) {
                previewWindow = window.open(
                  `${window.stagingServer}/${uri}/${id}`,
                  'child-window'
                );
              }

              previewWindow.focus();
            },
            className: 'btn-outline-primary',
          },
        ]}></Nav>

      {!loading && (
        <div className='row px-4'>
          <div className='col-md-9 '>
            <Card className={'mb-4'}>
              <Form
                onChange={updateData}
                inputs={[
                  {
                    label: 'intro text',
                    key: 'intro_text',
                    type: 'textarea',
                    required: true,
                    initialValue: data.data.intro_text,
                  },
                ]}></Form>

              <div className='row justify-content-center'>
                <div className='col-md-8'>
                  <Media
                    uri={data.data.elephant}
                    onChange={({ uri }) =>
                      updateData({ elephant: uri })
                    }></Media>
                </div>
              </div>
            </Card>

            <Card title='SEO'>
              <Form
                onChange={updateData}
                inputs={[
                  {
                    label: 'SEO title',
                    key: 'seo_title',
                    required: true,
                    initialValue: data.data.seo_title,
                  },
                  {
                    label: 'SEO description',
                    key: 'seo_description',
                    type: 'textarea',
                    required: true,
                    initialValue:
                      data.data.seo_description || data.data.intro_text,
                  },
                ]}></Form>
            </Card>
          </div>

          <div className='col-md-3'>
            <Card title='PAGE SETTINGS'>
              <Form
                onChange={(inputs) => {
                  setData({ ...data, ...inputs });
                }}
                inputs={[
                  {
                    label: 'Page Name',
                    key: 'name',
                    required: true,
                    initialValue: data.name,
                  },
                  {
                    label: 'Page URL',
                    key: 'uri',
                    disabled: true,
                    required: true,
                    initialValue: data.uri,
                  },
                ]}></Form>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
}

export default PageHome;
