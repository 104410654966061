import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ResizeSensor } from 'css-element-queries';
import { Trash } from 'react-bootstrap-icons';

const uid = uuidv4();

function Column({
  children,
  columnWidth = 'col-md-12',
  columnHeight,
  onChange,
  remove,
}) {
  const ref = useRef(null);

  useEffect(() => {
    // new ResizeSensor();
    var element = document.getElementById(uid);
    new ResizeSensor(element, function () {
      console.log('Changed to ' + element.clientWidth);

      if (onChange)
        onChange({
          columnWidth:
            'col-md-' +
            Math.round(
              (element.clientWidth /
                document.querySelector('#editable-space').clientWidth) *
                12
            ),
        });
    });

    // console.log(element, uid);
  }, []);

  return (
    <div className={'resizeable-container ' + columnWidth} id={uid} ref={ref}>
      <div
        className='container-tab-leaf action-icon'
        onClick={remove}
        contentEditable={false}>
        <Trash></Trash>{' '}
        <label className='label fw-normal m-0 d-inline-block'>remove</label>
      </div>

      {children}
    </div>
  );
}

export default Column;
