import React, { useEffect, useState } from 'react';
import { Search, SlashCircle } from 'react-bootstrap-icons';
import ReactPaginate from 'react-paginate';
import Loader from './loader';

function Table(props) {
  let [searchValue, setSearchValue] = useState('');
  let [$skip, setSkip] = useState(0);
  let [$limit, setLimit] = useState(10);
  let [total, setTotal] = useState(10);
  let [loading, setLoading] = useState(true);
  let [data, setData] = useState([]);

  // sorting
  let [sort, setSort] = useState(props.sort);
  let [sortDirection, setSortDirection] = useState(props.sortDirection);

  useEffect(() => {
    fetchData();
  }, [$skip, $limit]);

  let fetchData = async () => {
    setLoading(true);

    let params = {
      $skip: $skip,
      $limit,
    };

    // console.log(params, $skip);

    if (sort) params.$sort = sort;

    if (props.search) {
      // $or[0][archived][$ne]=true
      let search = searchValue.replace(/\s/, '+');

      let $or = [];
      props.search.map((d, i1) => {
        let s = {};
        s[d] = { $like: `%${search}%` };
        $or.push(s);
      });
      params.$or = $or;
    }

    await props.fetch(params);
    // setData(data.data);
    // setLimit(data.limit);
    // setSkip(data.skip);
  };

  useEffect(() => {
    // console.log(props.data);
    if (props.data) {
      setData(props.data.data);
      // setLimit(props.data.limit);
      // setSkip(props.data.skip);
      setTotal(props.data.total);
    }
  }, [props.data]);

  let handlePageClick = (event) => {
    const newOffset = event.selected * $limit;

    setSkip(newOffset);
  };

  useEffect(() => {
    setLoading(false);
  }, [data]);

  // useEffect(() => {
  //   setData(props.data.data);
  //   setSkip(props.data.skip);
  //   setLimit(props.data.limit);
  // }, [props.data]);

  useEffect(() => {
    fetchData();
  }, []);

  let getTableHeaders = () => {
    let titles = [];
    if (data.length) {
      let entry = data[0];
      if (props.entry) {
        entry = props.entry(entry);
      }

      titles = Object.keys(entry);
    }

    // console.log(titles);
    return titles;
  };

  return (
    <>
      <div className={'d-flex flex-fill flex-column blue-table'}>
        <div className='d-flex flex-row justify-content-between align-items-center '>
          {props.search && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                fetchData();
              }}
              className='d-flex flex-column justify-content-center position-relative'>
              <Search
                className='search-icon cursor-pointer '
                color='grey'
                onClick={props.fetchData}
              />
              <input
                type='search'
                className='form-control pl-5 rounded bg-light'
                value={searchValue}
                placeholder='Search'
                onChange={(e) => {
                  console.log({ searchValue: e.target.value });
                  setSearchValue({ searchValue: e.target.value });
                }}
              />
            </form>
          )}

          <div className='d-flex flex-row align-items-center entries-counter'>
            {/* {csv === true && (
            <button
              type='button'
              onClick={() => {
                this.setState({ CSVmodal: true });
              }}
              className='option-card pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-outline-primary btn-round btn-sm mr-3'>
              <Download size={16} />
              <span className='pl-2 font-weight-bold no-wrap'>
                Generate CSV
              </span>
            </button>
          )} */}

            <span className='me-2'>Show </span>
            <select
              className='form-control form-control-sm'
              onChange={(event) => {
                setLimit(parseInt(event.target.value));
                fetchData();
              }}>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <span className='ms-2'> entries </span>
          </div>
        </div>

        <div className='d-flex flex-fill tb-cover position-relative'>
          {loading && (
            <div className='d-flex flex-fill flex-row align-items-center justify-content-center '>
              <Loader loading={loading}></Loader>
            </div>
          )}

          {!loading && !data.length && (
            <div className='w-100 h-100 empty-holder bg-light d-flex flex-row align-items-center justify-content-center'>
              <div className='text-muted d-flex flex-column align-items-center'>
                <SlashCircle size={30} />
                <span className='mt-3'>No Data</span>
              </div>
            </div>
          )}

          {!loading && (
            <div className='table-container w-100'>
              <table className='table text-dark table-hover'>
                <thead>
                  <tr>
                    {/* {this.state.checkbox && (
                    <th className=''>
                      <input
                        type='checkbox'
                        className='styled-checkbox'
                        name=''
                        id='index'
                        checked={this.state.checkboxIDS.every((val) =>
                          this.state.checked.includes(val)
                        )}
                        onChange={this.checkAll}
                      />
                      <label for='index'></label>
                    </th>
                  )} */}

                    {data.length > 0 &&
                      getTableHeaders().map((title, i) => {
                        return (
                          <th
                            key={i}
                            className='text-capitalize'
                            onClick={() => {
                              setSort(title);
                              setSortDirection(sortDirection === -1 ? 1 : -1);
                              fetchData();
                            }}>
                            {title.replace(/_/g, ' ')}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {data.map((rawRow, index) => {
                    let row = rawRow;
                    if (props.entry) {
                      row = props.entry(row);
                    }

                    return (
                      <tr key={index} className='cursor-pointer'>
                        {Object.keys(row).map((column, i) => {
                          return (
                            <td key={i} className='table-data'>
                              {row[column]}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {!props.hidePagination && (
          <ReactPaginate
            nextLabel='next >'
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={Math.ceil(total / $limit)}
            previousLabel='< previous'
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link'
            nextClassName='page-item'
            nextLinkClassName='page-link'
            breakLabel='...'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            containerClassName='pagination'
            activeClassName='active'
            renderOnZeroPageCount={null}
          />
        )}
      </div>
    </>
  );
}

export default Table;
