import React, { useEffect, useState } from 'react';
import Nav from '../components/system/nav';
import PageEditor from '../components/page/basicEditor';
import Form from '../components/system/form';
import Card from '../components/card';
import { useParams } from 'react-router-dom';
import Media from '../components/media';
import BasicEdior from '../components/page/basicEditor';

let previewWindow = null;

function AboutEdit() {
  let [data, setData] = useState({ data: {} });
  let [loading, setLoading] = useState(true);

  let { id, uri } = useParams();

  let save = () => {
    window.app
      .service('pages')
      .patch(id, data)
      .then((response) => {
        window.Alert.notify({
          title: 'Page Updated',
        });
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let fetchPage = () => {
    window.app
      .service('pages')
      .get(id)
      .then((response) => {
        setData(response);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchPage();
  }, []);

  let updateData = (inputs) => {
    let d = data;
    d.data = { ...data.data, ...inputs };

    console.log(d);

    setData(d);
  };
  return (
    <div className=''>
      <Nav
        title={`Edit Page`}
        links={[
          {
            text: 'Save',
            onClick: save,
            className: 'btn-primary',
          },
          {
            text: 'Preview',
            onClick: () => {
              if (!previewWindow || previewWindow.closed) {
                previewWindow = window.open(
                  `${window.stagingServer}/#about-us`,
                  'child-window'
                );
              }

              previewWindow.focus();
            },
            className: 'btn-outline-primary',
          },
        ]}></Nav>

      {!loading && (
        <div className='row px-4'>
          <div className='col-md-9 '>
            <Card className={'mb-4'}>
              <Form
                onChange={updateData}
                inputs={[
                  {
                    key: 'title_1',
                    required: true,
                    initialValue: data.data.title_1,
                    className: 'clean',
                    style: {
                      fontSize: 28,
                      fontFamily: '"Libre Baskerville", serif',
                      fontWeight: 'bold',
                    },
                  },
                ]}></Form>

              <BasicEdior
                value={
                  data.data.content1 || [
                    {
                      id: 20,
                      type: 'paragraph',
                      children: [{ text: '' }],
                    },
                  ]
                }
                onChange={(content1) => {
                  updateData({ content1 });
                }}></BasicEdior>
            </Card>

            <Card className={'mb-4'}>
              <Form
                onChange={updateData}
                inputs={[
                  {
                    key: 'title_2',
                    required: true,
                    initialValue: data.data.title_2,
                    className: 'clean',
                    style: {
                      fontSize: 28,
                      fontFamily: '"Libre Baskerville", serif',
                      fontWeight: 'bold',
                    },
                  },
                  {
                    label: 'description',
                    key: 'description_2',
                    required: true,
                    type: 'textarea',
                    initialValue: data.data.description_2,
                  },
                ]}></Form>

              <DNASlots
                slots={data.data.slots}
                onChange={updateData}></DNASlots>
            </Card>

            <Card title='SEO'>
              <Form
                onChange={updateData}
                inputs={[
                  {
                    label: 'SEO title',
                    key: 'seo_title',
                    required: true,
                    initialValue: data.data.seo_title,
                  },
                  {
                    label: 'SEO description',
                    key: 'seo_description',
                    type: 'textarea',
                    required: true,
                    initialValue:
                      data.data.seo_description || data.data.intro_text,
                  },
                ]}></Form>
            </Card>
          </div>

          <div className='col-md-3'>
            <Card title='PAGE SETTINGS'>
              <Form
                onChange={(inputs) => {
                  setData({ ...data, ...inputs });
                }}
                inputs={[
                  {
                    label: 'Page Name',
                    key: 'name',
                    required: true,
                    initialValue: data.name,
                  },
                  {
                    label: 'Page URL',
                    key: 'uri',
                    disabled: true,
                    required: true,
                    initialValue: data.uri,
                  },
                ]}></Form>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
}

function DNASlots(props) {
  let [slots, setSlots] = useState(props.slots || [{}, {}, {}]);

  return (
    <div className='row'>
      {slots.map((slot, i) => {
        console.log(slot);

        return (
          <div className='col-md-4' key={i}>
            <Form
              onChange={(data) => {
                let $slots = slots;
                $slots[i] = data;
                setSlots($slots);
                props.onChange({ slots: $slots });
              }}
              inputs={[
                {
                  label: 'Title',
                  key: 'title',
                  required: true,
                  initialValue: slot.title,
                },
                {
                  label: 'description',
                  key: 'description',
                  type: 'textarea',
                  required: true,
                  initialValue: slot.description,
                },
                {
                  label: 'Link',
                  key: 'link',
                  type: 'url',
                  required: true,
                  initialValue: slot.link,
                },
              ]}></Form>
          </div>
        );
      })}
    </div>
  );
}

export default AboutEdit;
