import React, { useEffect, useState } from 'react';

function Input({
  placeholder,
  type,
  className,
  label,
  description,
  onChange,
  required,
  initialValue,
  disabled,
  style,
  size,
  autocomplete,
}) {
  let [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (initialValue !== value) setValue(initialValue);
  }, [initialValue]);

  return (
    <div class={'form-group custom-input mb-3  ' + (size || 'col-md-12')}>
      {label && <label className='label'>{label}</label>}
      {type === 'textarea' ? (
        <textarea
          rows='4'
          type={type}
          className={'form-control ' + className}
          placeholder={placeholder || '...'}
          required={required}
          value={value}
          disabled={disabled}
          style={style}
          onChange={(e) => {
            onChange(e.target.value);
            setValue(e.target.value);
          }}></textarea>
      ) : (
        <input
          type={type}
          className={'form-control ' + className}
          placeholder={placeholder || '...'}
          required={required}
          value={value}
          style={style}
          onChange={(e) => {
            onChange(e.target.value);
            setValue(e.target.value);
          }}
          disabled={disabled}
          autocomplete={autocomplete}
        />
      )}
      {description && (
        <small class='form-text text-muted description'>{description}</small>
      )}
    </div>
  );
}

export default Input;
