import React, { useEffect, useState } from 'react';
import Nav from '../components/system/nav';
import PageEditor from '../components/page/basicEditor';
import Form from '../components/system/form';
import Card from '../components/card';
import { useParams } from 'react-router-dom';
import Swiper from '../components/swiper';
import BasicEdior from '../components/page/basicEditor';
import Media from '../components/media';

let previewWindow = null;

function BlogEdit() {
  let [data, setData] = useState({ data: {} });
  let [loading, setLoading] = useState(true);
  let [categories, setCategories] = useState([]);

  let categoryName;

  let { id, uri } = useParams();

  let save = () => {
    window.app
      .service('pages')
      .patch(id, data)
      .then((response) => {
        window.Alert.notify({
          title: 'Page Updated',
        });
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let fetchPage = () => {
    window.app
      .service('pages')
      .get(id)
      .then((response) => {
        setData(response);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let fetchCategories = () => {
    window.app
      .service('page-category')
      .find({
        query: {
          status: 2,
        },
      })
      .then((response) => {
        console.log('page categories ', response);
        setCategories(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchPage();
    fetchCategories();
  }, []);

  let updateData = (inputs) => {
    let d = data;
    d.data = { ...data.data, ...inputs };

    if (!d.data.seo_title || !d.data.seo_description) {
      d.data.seo_title = d.title;
      d.data.seo_description = d.description;
    }

    setData(d);

    // console.log(data);
  };

  let createCategory = () => {
    console.log(categoryName);
    window.app
      .service('page-category')
      .create({
        name: categoryName,
        status: 2,
      })
      .then((response) => {
        let article = { ...data };
        article.pageCategoryId = response.id;
        categoryName = '';

        let $categories = [...categories];
        $categories.push(response);
        setCategories($categories);

        setData(article);
      });
  };

  return (
    <div className=''>
      <Nav title={`Edit ${data.name}`}></Nav>

      {!loading && (
        <div className='row px-4'>
          <div className='col-md-9 '>
            <Card className={'mb-4'}>
              {/* {'ss' + data.data.title} */}

              <Form
                onChange={updateData}
                inputs={[
                  {
                    label: 'title',
                    key: 'title',
                    required: true,
                    initialValue: data.data.title,
                  },
                  {
                    label: 'description',
                    key: 'description',
                    type: 'textarea',
                    required: true,
                    initialValue: data.data.description,
                  },
                ]}></Form>
              <div className='label'>Banner</div>
              <Media
                onChange={({ uri }) => {
                  updateData({ banner: uri });
                }}
                uri={data.data.banner}></Media>
            </Card>

            <Card className='mb-4'>
              <BasicEdior
                value={
                  data.data.content || [
                    {
                      id: 20,
                      type: 'paragraph',
                      children: [{ text: '' }],
                    },
                  ]
                }
                onChange={(content) => {
                  updateData({ content });
                }}></BasicEdior>
            </Card>

            <Card title='SEO'>
              <Form
                onChange={updateData}
                inputs={[
                  {
                    label: 'SEO title',
                    key: 'seo_title',
                    required: true,
                    initialValue: data.data.seo_title,
                  },
                  {
                    label: 'SEO description',
                    key: 'seo_description',
                    type: 'textarea',
                    required: true,
                    initialValue:
                      data.data.seo_description || data.data.intro_text,
                  },
                ]}></Form>
            </Card>
          </div>

          <div className='col-md-3 position-relative'>
            <Card title='PAGE SETTINGS' className='sticky-card'>
              <Form
                onChange={(inputs) => {
                  setData({ ...data, ...inputs });
                }}
                inputs={[
                  {
                    label: 'Page Name',
                    key: 'name',
                    required: true,
                    initialValue: data.name,
                  },
                  {
                    label: 'Page URL',
                    key: 'uri',
                    disabled: true,
                    required: true,
                    initialValue: data.uri,
                  },
                ]}></Form>

              <label className='label'>category</label>
              {categories.length > 0 && (
                <select
                  className='form-control'
                  value={data.pageCategoryId}
                  onChange={(e) => {
                    if (e.target.value == 0) {
                      window.alert2.show({
                        title: 'Add Category',
                        message: (
                          <div>
                            <div class='form-group mb-0'>
                              <input
                                onChange={(e) => {
                                  console.log(e.target.value);
                                  categoryName = e.target.value;
                                }}
                                type='text'
                                required={true}
                                class='form-control'
                                placeholder='Enter Category'
                              />
                            </div>
                          </div>
                        ),
                        close: true,
                        onSubmit: () => {
                          createCategory();
                        },
                      });

                      return false;
                    }

                    let article = { ...data };
                    article.pageCategoryId = e.target.value;
                    setData(article);
                  }}>
                  <option value='' hidden selected>
                    Choose Category
                  </option>
                  {categories.map((d) => (
                    <option value={d.id}>{d.name}</option>
                  ))}
                  <option value='0'>Other</option>
                </select>
              )}

              <label className='d-flex flex-row align-items-center mt-3'>
                <input
                  className='form-check-input mt-0 me-3'
                  type='checkbox'
                  checked={data.published === 1}
                  onChange={(e) => {
                    let $data = { ...data };
                    $data.published = e.target.checked ? 1 : 3;

                    console.log($data.published);
                    setData($data);
                  }}
                />

                <div className='label m-0'>Publish</div>
              </label>
              <div className='row mt-3'>
                <div className='col-md-6'>
                  <button
                    className='btn btn-outline-primary w-100'
                    onClick={save}>
                    Save
                  </button>
                </div>
                <div className='col-md-6'>
                  <button
                    className='btn btn-outline-primary w-100'
                    onClick={() => {
                      if (!previewWindow || previewWindow.closed) {
                        previewWindow = window.open(
                          `${window.stagingServer}/article/${uri}/${id}`,
                          'blog-window'
                        );
                      }

                      previewWindow.focus();
                    }}>
                    Preview
                  </button>
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
}

export default BlogEdit;
